
import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'
import { projectList, scrumboardData, issueData, projectDashboardData } from './data/projectData'
import { productsData, ordersData, orderDetailsData, salesDashboardData } from './data/salesData'
import { usersData, userDetailData } from './data/usersData'
import { 
    settingData, 
    settingIntergrationData, 
    settingBillingData, 
    invoiceData, 
    logData,
    accountFormData
} from './data/accountData'

import { signInUserData } from './data/authData'

import {
    projectFakeApi,
    salesFakeApi,
    accountFakeApi,
    authFakeApi
} from './fakeApi'

const { apiPrefix } = appConfig

export default function mockServer({ environment = 'test' }) {
    return createServer({
        environment,
        seeds(server) {
			server.db.loadData({
                projectList, 
                scrumboardData, 
                issueData, 
                projectDashboardData,
                usersData, 
                userDetailData,
                settingData, 
                settingIntergrationData, 
                settingBillingData, 
                invoiceData, 
                logData,
                accountFormData,
                signInUserData,
                productsData,
                ordersData, 
                orderDetailsData, 
                salesDashboardData
			})
		},
        routes() {
            this.urlPrefix = ''
            this.namespace = ''
            this.passthrough(request => {
                let isExternal = request.url.startsWith('http')
                return isExternal
            })
            this.passthrough()
            
            projectFakeApi(this, apiPrefix)
            salesFakeApi(this, apiPrefix)
            accountFakeApi(this, apiPrefix)
            authFakeApi(this, apiPrefix)
        },
    })
}