
const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/projects',
    authenticatedEntryPathAdmin: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    verifiedPath:'/verified',
    tourPath: '/',
    enableMock: false
}

export default appConfig