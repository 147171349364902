import FormService from './FormService'

const ApiFormService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            FormService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
}

export default ApiFormService