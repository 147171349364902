import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { apiUpdateProfile, apiChangePassword } from 'services/AuthService'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
			const resp = await apiSignIn(values)
			if (resp.data) {
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				if(resp.data.is_verified == true){
					const { token } = resp.data
					dispatch(onSignInSuccess(token))
					if(resp.data) {
						dispatch(setUser(resp.data))
					}
					if(resp.data.authority[0] === 'ADMIN'){
						navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPathAdmin)
						return {
							status: 'success',
							message: ''
						}
					}
					else{
						navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
						return {
							status: 'success',
							message: ''
						}
					}
				}
				else{
					return {
						status: 'success',
						message: 'We have sent you an Email, Please verify your account'
					}
				}
				
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors.response.data.msg || errors.toString()
            }
		}
    }

	const signUp = async (values) => {
        try {
			const resp = await apiSignUp(values)
			const redirectUrl = query.get(REDIRECT_URL_KEY)
			if (resp.data) {
				return {
					status: 'success',
					message: 'We have sent you an Email, Please verify your account'
				}
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors.response.data.email || JSON.stringify(errors.response.data)
            }
		}
    }

	const updateProfile = async(values) => {
		try {
			const resp = await apiUpdateProfile(values)
			if (resp.data) {
				dispatch(setUser(resp.data))
				return {
					status: 'success',
					message: 'Update Successful'
				}
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors.toString() || JSON.stringify(errors.response.data)
            }
		}
	}

	const changePassword = async(values) => {
		try {
			const resp = await apiChangePassword(values)
			if (resp.data) {
				return {
					status: 'success',
					message: 'Update Successful'
				}
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors.response.data.email || JSON.stringify(errors.response.data)
            }
		}
	}

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		await apiSignOut()
		handleSignOut()
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
		signUp,
        signOut,
		updateProfile,
		changePassword
    }
}

export default useAuth