import ApiService from './ApiService'
import ApiFormService from './ApiFormService';

let {login, logout, signup, forgot, reset} = '';

// Code for getting env variables.
//if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {}

login = '/accounts/login';
logout = '/accounts/logout';
signup = '/accounts/register';
forgot = '/forgot-password';
reset = '/reset-password';

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: login,
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: signup,
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: logout,
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: 'request-reset/',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: 'new-password/',
        method: 'put',
        data
    })
}

export async function apiChangePassword (data) {
    return ApiService.fetchData({
        url: 'accounts/change-password',
        method: 'post',
        data
    })
}

export async function apiUpdateProfile (data) {
    return ApiFormService.fetchData({
        url: 'accounts/update',
        method: 'put',
        data
    })
}

