export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const REQUEST_HEADER_API_KEY = 'x-api-key'
export const TASK_QUEUE_API_KEY = 'Bearer LkahsjdhjHAWURyuihASNDMnzmxcASlkasdASjkj12009523kkjhaskjdh247777'
export const VM_HOST_SERVER = 'workers.blendexpo.com'
export const APPENGINE_HOST_SERVER = 'blendexpo-workers-v7.uw.r.appspot.com'
export const SWW = 'There’s something wrong, please try again.'

// Your web app's Firebase configuration
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA2T3kQxVRy3xQ_Vpp_TTfPL_QaSZqwsxE",
  authDomain: "blendexpo-workers-v7.firebaseapp.com",
  projectId: "blendexpo-workers-v7",
  storageBucket: "blendexpo-workers-v7.firebasestorage.app",
  messagingSenderId: "727069002191",
  appId: "1:727069002191:web:d2c6e2ac696bf8100beaba"
};
