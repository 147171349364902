import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: -1,
    first_name: '',
    last_name: '',
    profile_image: '',
    thumbnail: '',
    middle_name: '',
    suffix_name: '', 
    gender: '', 
    age: 0, 
    birth_date: '', 
    address: '', 
    marital_status: '',
    invoicing_data_1: 0,
    invoicing_data_2: 0,
    authority: [],
    paypal_token: '',
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer